import React, { useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams, Link, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";

import { is2004 } from "offline-tracker/api";

import "./CourseDiploma.scss";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";
import { deleteAccessStatusWithRestart } from "tracker-api/sessions";

import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import { ReactComponent as TodoIcon } from "images/icons/todo.svg";
import { ReactComponent as MeetingIcon } from "images/icons/meeting.svg";
import { ReactComponent as IdZeroSixIcon } from "images/icons/id06.svg";
import { ReactComponent as CertIcon } from "images/icons/cert.svg";

import { MY_PAGES_URL, IS_SCORM, IS_DEMO, FASTTRACK_DIPLOMA_URL } from "consts";

function CourseDiploma(props) {
  const { courseSlug } = useParams();
  const { data } = useCurrentCourse();
  const { t } = useTranslation();
  const { search } = useLocation();
  const user = useSelector(getUserWithAccessStatus);
  const eduadminId = useCurrentEduadminId();
  const languageCode = useSelector(state => state.layout.currentLanguage);

  const resetCourseStatus = useCallback(() => {
    async function _resetCourse() {
      await deleteAccessStatusWithRestart(eduadminId, user);
      window.location.reload();
    }
    _resetCourse()
  }, [eduadminId, user]);

  if (!data || (data && !data.course)) return null;

  const personName = IS_SCORM
    ? props.sco.learnerName
    : user && user.profile
    ? `${user.profile.given_name} ${user.profile.family_name}`
    : "";

  const companyName =
    user && user.profile
      ? user.profile[
          "https://sverigesbyggindustrier.se/company_registration_number"
        ]
      : "";
  const courseTitle = data.course.titleRendered;
  // const successDate = "2020-04-22"; // TODO
  const successDate = props.isFasttrack ? new Date(user && user.accessStatus && user.accessStatus.certIssuedDate).toLocaleDateString(languageCode) : "";
  const surveyLink =
    data.course.blbGroupCourseSurvey &&
    data.course.blbGroupCourseSurvey.blbCourseSurveyLink
      ? data.course.blbGroupCourseSurvey.blbCourseSurveyLink
      : null;

  const disableId06 =
    (data.course.blbGroupCourseId06 &&
      data.course.blbGroupCourseId06.blbCourseId06DisableLink) ||
    IS_SCORM || IS_DEMO;

  const showCertificate = () => {
    window.open(FASTTRACK_DIPLOMA_URL, "_blank");
  };

  const showRundownLink = !IS_SCORM || (IS_SCORM && is2004(props.sco));

  return (
    <div className="Course-diploma">
      <h1 className="Course-diploma__coursename">{courseTitle}</h1>
      <div className="Course-diploma__inner">
        <h2 className="Course-diploma__confirmationheading">{t("PASSED")}</h2>
        {props.isFasttrack && !IS_DEMO ? (
          <>
            <p className="Course-diploma__informationtext">{t("SCT_DELAY_INFO_TEXT")}</p>
            <p className="Course-diploma__confirmationtext">{t("SCT_SUCCESS_TEXT_0")}&nbsp;&nbsp;</p>
            <p className="Course-diploma__confirmationtext">{t("SCT_SUCCESS_TEXT_1", { date: successDate })}</p>
            <div className="Course-diploma__sct-actions__button-row">
              <button className="Course-diploma__sct-actions__button Course-diploma__sct-actions__button--certificate" type="button" onClick={showCertificate}>
                {t("SHOW_CERTIFICATE")}
              </button>
              <button className="Course-diploma__sct-actions__button Course-diploma__sct-actions__button--course" onClick={resetCourseStatus}>
                {t("REDO_THE_COURSE")}
              </button>
            </div>
          </>
        ) : (
          <>
            { IS_DEMO ? (
              <>
                <p className="Course-diploma__confirmationtext">
                  {t("DEMO_SUCCESS_TEXT_0")}
                </p>
                <p className="Course-diploma__confirmationtext">
                  {t("DEMO_SUCCESS_TEXT_1")}
                </p>
              </>
            ) : (  
              <p className="Course-diploma__confirmationtext">
                {companyName ? (
                  <Trans
                    i18nKey="X_HAS_COMPLETED_XS_COURSE_X_WITH_PASSED_GRADE"
                    personName={personName}
                    companyName={companyName}
                    courseTitle={courseTitle}
                  >
                    <span>{{ personName }}</span>
                    <span>{{ companyName }}</span>
                    <strong className="Bold">{{ courseTitle }}</strong>
                  </Trans>
                ) : (
                  <Trans
                    i18nKey="X_HAS_COMPLETED_COURSE_X_WITH_PASSED_GRADE"
                    personName={personName}
                    courseTitle={courseTitle}
                  >
                    <span>{{ personName }}</span>
                    <strong className="Bold">{{ courseTitle }}</strong>
                  </Trans>
                )}{" "}
                {successDate ? (
                  <span>
                    {t("THE_COURSE_WAS_COMPLETED_ON_X", { date: successDate })}
                  </span>
                ) : null}
              </p>
            )}
            <div className="Course-diploma__actions">
              {IS_SCORM || IS_DEMO ? null : (
                <a href={MY_PAGES_URL} className="Course-diploma__action">
                  <span className="Course-diploma__actioninner">
                    <span className="Course-diploma__actionicon">
                      <CertIcon />
                    </span>
                    <span className="Course-diploma__actiontext">
                      {t("GET_CERTIFICATE")}
                    </span>
                  </span>
                </a>
              )}
              {showRundownLink ? (
                <Link
                  to={{ pathname: `/course/${courseSlug}/summary`, search }}
                  className="Course-diploma__action"
                >
                  <span className="Course-diploma__actioninner">
                    <span className="Course-diploma__actionicon Course-diploma__actionicon--key">
                      <TodoIcon />
                    </span>
                    <span className="Course-diploma__actiontext">
                      {t("COMPLETED_TASKS_WITH_KEY")}
                    </span>
                  </span>
                </Link>
              ) : null}
              {surveyLink ? (
                <a
                  href={surveyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Course-diploma__action"
                >
                  <span className="Course-diploma__actioninner">
                    <span className="Course-diploma__actionicon Course-diploma__actionicon--feedback">
                      <MeetingIcon />
                    </span>
                    <span className="Course-diploma__actiontext">
                      {t("WHAT_DID_YOU_THINK_GIVE_FEEDBACK")}
                    </span>
                  </span>
                </a>
              ) : null}
              {disableId06 ? null : (
                <a href={MY_PAGES_URL} className="Course-diploma__action">
                  <span className="Course-diploma__actioninner">
                    <span className="Course-diploma__actionicon">
                      <IdZeroSixIcon />
                    </span>
                    <span className="Course-diploma__actiontext">
                      {t("REGISTER_IN_ID06")}*
                    </span>
                  </span>
                </a>
              )}
            </div>
            {disableId06 ? null : (
              <p className="Course-diploma__disclaimer">
                * {t("ID06_DISCLAIMER_TEXT")}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const Comp = IS_SCORM ? withScorm()(CourseDiploma) : CourseDiploma;

export default Comp;
