import envConfig from "environment.json";
import { IS_SCORM, IS_DEMO } from "consts";

const rollbarConfig = {
  enabled: !IS_SCORM && !IS_DEMO,
  accessToken: "8c5b8cedd1634391839fe259e68c36f2",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: envConfig.ROLLBAR_ENV
  }
};

export default rollbarConfig;
