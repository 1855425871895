import _ from "lodash";

import envConfig from "environment.json";
import getUserManager from "userManager";

import fetchErrorHandler from "utils/fetchErrorHandler";

import getTrackerActorFromUser from "utils/getTrackerActorFromUser";
import getTrackerReqHeaders from "utils/getTrackerReqHeaders";
import sendRollbar from "utils/sendRollbar";

import { IS_SCORM, IS_DEMO } from "consts";
import { getCourseState as getOfflineCourseState } from "offline-tracker/api";

export const isLoggedIn = async user => {
  const url = envConfig.TRACKER_REST_API_URI + "/user/me";

  const res = await fetch(url, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    // .then(fetchErrorHandler)
    .then(_res => {
      if (!_res.ok) return {};
      return _res.json();
    })
    .then(json => {
      return !!json.email;
    })
    .catch(err => {
      console.error(err);
    });
  return res;
};

export const getCourseState = async (
  eduadminId,
  user,
  silentError = true,
  sco = null
) => {
  if (IS_SCORM || IS_DEMO) return getOfflineCourseState(sco);

  const url = envConfig.TRACKER_REST_API_URI + "/coursestate";

  const actor = getTrackerActorFromUser(user);

  if (_.isEmpty(actor)) throw Error("No user");

  const { ssoId } = actor.account;

  const fullUrl = `${url}/${eduadminId}/${ssoId}`;
  const headers = getTrackerReqHeaders(user);
  const reqInputForError = { eduadminId, ssoId };

  let tempRes;
  const res = await fetch(fullUrl, {
    method: "GET",
    headers
  })
    .then(_res => fetchErrorHandler(_res, reqInputForError, "getCourseState"))
    .then(_res => {
      tempRes = _res;
      return _res.json();
    })
    .then(parsedJson => {
      if (parsedJson && !_.isEmpty(parsedJson)) return parsedJson;

      // If we for some reason got an
      // empty response from API,
      // which can happen in rare cases,
      // we log it and retry
      const userManager = getUserManager();
      sendRollbar(
        tempRes,
        reqInputForError,
        "getCourseState: Got empty response, retrying",
        userManager
      );

      return fetch(fullUrl, {
        method: "GET",
        headers
      })
        .then(_res =>
          fetchErrorHandler(_res, reqInputForError, "getCourseState")
        )
        .then(_res => {
          return _res.json();
        })
        .then(parsedJson => {
          return parsedJson;
        })
        .catch(err => {
          throw err;
        });
    })
    .then(parsedJson => {
      return parsedJson;
    })
    .catch(err => {
      console.error(err);
      if (!silentError) throw err;
    });
  return res;
};

export const getParticipantId = async (eduadminId, user) => {
  const url = envConfig.TRACKER_REST_API_URI + `/participant/${eduadminId}`;
  const res = await fetch(url, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res => fetchErrorHandler(_res, { eduadminId }, "getParticipantId"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });

  return res;
};

export const getAccessStatus = async (eduadminId, user, silentError = true) => {
  const url = envConfig.TRACKER_REST_API_URI + `/access/check/${eduadminId}`;
  const res = await fetch(url, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res => fetchErrorHandler(_res, { eduadminId }, "getAccessStatus"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      if(!silentError) throw new Error(err);
    });

  return res;
};

export const deleteAccessStatus = async (eduadminId, user) => {
  const url = envConfig.TRACKER_REST_API_URI + `/access/abort/${eduadminId}`;
  const res = await fetch(url, {
    method: "DELETE",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res => fetchErrorHandler(_res, { eduadminId }, "deleteAccessStatus"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });

  return res;
};

export const deleteAccessStatusWithRestart = async (eduadminId, user) => {
  const url = envConfig.TRACKER_REST_API_URI + `/access/abortwithrestart/${eduadminId}`;
  const res = await fetch(url, {
    method: "DELETE",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res => fetchErrorHandler(_res, { eduadminId }, "deleteAccessStatusWithRestart"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });

  return res;
};
