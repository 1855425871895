import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setAccessStatus, setFullCourseState, setAvailableLanguages } from "actions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import { postCourseInitialized } from "tracker-api/statements";
import { getCourseState, getAccessStatus } from "tracker-api/sessions";

import debugLog from "utils/debugLog";
import { IS_BYPASS_SSO, IS_DEMO, IS_SCORM } from "consts";
import envConfig from "environment.json";

function useSetupCourse(data, skipInit, sco) {
  const dispatch = useDispatch();
  const user = useSelector(getUserWithAccessStatus);
  const courseState = useSelector(state => state.course.courseState);
  const { courseSlug } = useParams();
  const eduadminId = useCurrentEduadminId();

  // Start with true to avoid flickering
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isDoneSession, setIsDoneSession] = useState(false);

  const [sessionError, setSessionError] = useState(null);

  // get available languages and slugs for SCT courses
  useEffect(() => {
    if (!IS_DEMO && !IS_SCORM) {
      const _getLanguages = async function () {
        const url = envConfig.SCT_LANGUAGES_REST_URI;
        
        const res = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        })
        .then(_res => {
          if (!_res.ok) return {};
          return _res.json();
        })
        .then(json => {
          dispatch(setAvailableLanguages(json));
        })
        .catch(err => {
          console.error(err);
        });
        return res;
      };
     _getLanguages();
    }
  }, [dispatch]);

  // Post and get stuff from tracker
  useEffect(() => {
    async function _postCourseInitialized() {
      if (isLoadingSession || sessionError) return;

      debugLog("Running course setup");

      setIsLoadingSession(true);

      let _user = { ...user };
      let isParticipant = false;
      if (!IS_BYPASS_SSO && !_user.accessStatus) {
        debugLog("Checking if participant");
        let accessStatusRes;
        try {
          accessStatusRes = await getAccessStatus(eduadminId, _user, false);
        } catch(e) {
          setSessionError(e);
        }

        if (accessStatusRes) {
          dispatch(setAccessStatus(accessStatusRes));

          isParticipant = accessStatusRes.hasAccess || accessStatusRes.isPreview;

          // Populate local user object for
          // usage below
          _user = { ..._user, accessStatus: accessStatusRes };
        } else {
          dispatch(setAccessStatus(null));
        }
      } else {
        isParticipant = true;
      }

      if (isParticipant && !courseState) {
        if (!skipInit) {
          await postCourseInitialized(data.course, eduadminId, _user, sco);
        }
        debugLog("Getting course state");

        let getRes;
        try {
          getRes = await getCourseState(eduadminId, _user, false, sco);
        } catch (e) {
          // Stop effect from trying again
          setSessionError(e);
        }

        if (getRes) {
          debugLog("Got full course state", getRes);

          dispatch(setFullCourseState(getRes));
          setSessionError(null);
        }
      }
      setIsDoneSession(true);

      setIsLoadingSession(false);
    }

    if (data && data.course && (eduadminId || IS_DEMO || IS_SCORM) && (user || IS_BYPASS_SSO)) {
      // Wait for CMS course id before posting to tracker
      setIsDoneSession(false);
      _postCourseInitialized();
      document.title = data.course.titleRendered;
    } else if (!user && !IS_BYPASS_SSO) {
      // If there is no user, short-circut to
      // 'done' so that callee can display login
      setIsDoneSession(true);
    }
  }, [
    isLoadingSession,
    sessionError,
    courseState,
    courseSlug,
    data,
    dispatch,
    eduadminId,
    skipInit,
    user,
    sco,
    setIsDoneSession,
    setIsLoadingSession
  ]);

  return { isLoadingSession, isDoneSession, sessionError };
}

export default useSetupCourse;
