import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withScorm } from "react-scorm-provider";

import "./MainFooter.scss";

import {
  setSelectedSection,
  setSelectedChapter,
  setSectionToResultView
} from "actions";

import {
  ENTITY_STATUSES,
  IS_DEBUG,
  MY_PAGES_URL,
  GQL_SECTIONS_NEST,
  IS_SCORM,
  IS_DEMO
} from "consts";

import HomeLink from "components/HomeLink/HomeLink";

import useCurrentCourse from "hooks/useCurrentCourse";
import useSectionProgress from "hooks/useSectionProgress";
import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

function MainFooterSectionLink(props) {
  const { t } = useTranslation();

  const section = {
    ...props.section,
    progress: useSectionProgress(props.section)
  };

  return (
    <li className="Main-footer__listitem">
      <a
        href="#"
        className="Main-footer__link"
        onClick={e => {
          e.preventDefault();
          props.sectionGo(section);
        }}
      >
        <BackIcon />
        {t("X_SECTIONS_OVERVIEW", {
          sectionTitle: section.blbCourseSectionTitle
        })}
      </a>
    </li>
  );
}

function MainFooter() {
  const dispatch = useDispatch();
  const { data } = useCurrentCourse();
  const { t } = useTranslation();
  const courseState = useSelector(state => state.course.courseState);
  const selectedChapter = useSelector(state => state.course.selectedChapter);
  const chapterNavExpanded = useSelector(
    state => state.layout.chapterNavExpanded
  );
  const waitedLongEnough = useSelector(state => state.layout.waitedLongEnough);
  const user = useSelector(getUserWithAccessStatus);

  const sections = data ? _.get(data, GQL_SECTIONS_NEST) : null;

  // Wait for everything to load
  if (!sections || !courseState || !waitedLongEnough || chapterNavExpanded)
    return null;

  const sectionGo = section => {
    dispatch(setSelectedChapter(null));

    if (!IS_SCORM && section.progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS) {
      dispatch(setSectionToResultView(section.blbCourseSectionId));
    } else {
      dispatch(setSelectedSection(section.blbCourseSectionId));
    }
  };

  const isFasttrack = data?.course?.blbGroupCourseEduadminId?.blbCourseIsFasttrack;

  const cls =
    "Main-footer" + (selectedChapter ? " Main-footer--inChapter" : "");

  return (
    <footer className={cls}>
      <div className="Main-footer__inner">
        <h2 className="Main-footer__heading">{t("HARD_TO_FIND_GO_TO")}</h2>
        <ul className="Main-footer__list">
          {IS_DEBUG ? (
            <li className="Main-footer__listitem">
              <Link to={{ pathname: `/` }} className="Main-footer__link">
                Debug page
              </Link>
            </li>
          ) : null}

          <li className="Main-footer__listitem">
            <HomeLink
              iconComponent={BackIcon}
              className="Main-footer__link"
              linkText={t("WHOLE_COURSES_OVERVIEW")}
            />
          </li>
          {sections.map((section, i) => {
            // TODO double check the target of these
            return (
              <MainFooterSectionLink
                sectionGo={sectionGo}
                section={section}
                key={i}
              />
            );
          })}
          {IS_SCORM || IS_DEMO || isFasttrack ? null : (
            <li className="Main-footer__listitem">
              <a
                className="Main-footer__link"
                target="_blank"
                rel="noopener noreferrer"
                href={MY_PAGES_URL}
              >
                <BackIcon />
                {t("SEE_ALL_MY_COURSES")}
              </a>
            </li>
          )}
        </ul>
      </div>
    </footer>
  );
}

const Comp = IS_SCORM ? withScorm()(MainFooter) : MainFooter;

export default Comp;
